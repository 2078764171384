import { Box, Image, Text } from '@chakra-ui/react';
import { CartItemObject } from '../../interfaces/components';
import WalmartLogo from '../../assets/images/WM-Realm Logo-Latest.png';
// import TopOverlay from '../../assets/images/TopOverlay.webp';
import React from 'react';
import { Cart } from '../../Icons/Cart';
import { Heart } from '../../Icons/Heart';

interface NavbarProps {
  cartItems: CartItemObject[];
  // activeView: string;
  // navbarOptions: NavbarOption[];
  setCartActive: (state: boolean) => void;
  openWishlist: () => void;
}

function Navbar({ cartItems, setCartActive, openWishlist }: NavbarProps) {
  // const getNavLinks = (navbarOptions: NavbarOption[]) =>
  //   navbarOptions.map((navbarOption: NavbarOption) => (
  //     <Link to={navbarOption.href} key={navbarOption.key}>
  //       <Box
  //         color="white"
  //         opacity={activeView === navbarOption.key ? '1' : '0.6'}
  //         borderBottom={activeView === navbarOption.key ? '2px solid white' : '2px solid transparent'}
  //         fontFamily={activeView === navbarOption.key ? 'Bogle-Bold' : 'Bogle-Black'}
  //         fontSize={['14px', '16px', '16px', '18px', '18px']}
  //         _hover={{
  //           borderBottom: '2px solid white',
  //           opacity: '1',
  //           fontFamily: 'Bogle-Black',
  //         }}
  //         cursor="pointer"
  //         padding={['5px 10px', '5px 12px', '5px 12px', '5px 15px', '5px 15px']}
  //         transition="0.2s all ease-in"
  //       >
  //         {navbarOption.text}
  //       </Box>
  //     </Link>
  //   ));

  return (
    <Box
      height={['60px', '60px', '70px', '70px', '70px']}
      w={['100%']}
      padding={['0px 20px', '0px 20px', '0px 40px', '0px 60px', '0px 100px']}
      display="flex"
      justifyContent="space-between"
      position="fixed"
      top="0px"
      bg="transparent"
      zIndex="95"
      gap={[4, 4, 4]}
    >
      <Box
        width={['auto', 'auto', '100%']}
        height={['inherit']}
        display="flex"
        alignItems="center"
        gap={[4, 4, 4, 10, 10]}
      >
        <Image
          src={WalmartLogo}
          height={['32px', '32px', '40px', '40px', '40px']}
          width="95px"
          objectFit="contain"
          alt="walmart logo"
          loading="lazy"
        />

        {/* <Box display={['none', 'none', 'flex']} alignItems="center" gap={4} width={['100%']} position="relative">
          <Box position="absolute" display="flex" alignItems="center" gap={[1, 2, 2, 4, 4]}>
            {getNavLinks(navbarOptions)}
          </Box>
        </Box> */}
      </Box>

      {/* <Box
        display={['flex', 'flex', 'none']}
        alignItems="center"
        gap={4}
        width={['135px', '160px', '200px']}
        position="relative"
      >
        <Box position="absolute" display="flex" alignItems="center" gap={[1, 2, 2, 4, 4]}>
          {getNavLinks(navbarOptions)}
        </Box>
      </Box> */}

      <Box width={['fit-content']} height={['inherit']} display="flex" alignItems="center" gap={5}>
        <Box
          justifyContent="center"
          alignItems="center"
          zIndex="9"
          onClick={() => setCartActive(true)}
          cursor="pointer"
          position="relative"
        >
          <Cart boxSize={[5, 5, 6]} />
          <Text
            color="white"
            fontSize={['10px']}
            fontFamily="Bogle"
            fontWeight="700"
            position="absolute"
            width="fit-content"
            top="0px"
            left="3px"
            right="0px"
            margin="auto"
          >
            {cartItems.length}
          </Text>
        </Box>
        <Heart boxSize={[5, 5, 6]} stroke="white" onClick={openWishlist} cursor="pointer" />
      </Box>
    </Box>
  );
}

export default Navbar;
