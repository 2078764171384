import { Box, Button, Text, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { CartItemObject, WishlistItemObject } from '../../interfaces/components';
// import SplashDesktop from '../../assets/videos/SplashDesktopMinified.mp4';
// import SplashMobile from '../../assets/videos/SplashMobileMinified.mp4';
import TopOverlay from '../../assets/images/TopOverlay.webp';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { useGlobalStore } from '../../store/global';
// import ReactPlayer from 'react-player';
// import useWindowDimensions from '../../utils/useWindowDimensions';
import CartDrawer from './Cart';
import useViewportHeight from '../../utils/useViewPortHeight';
import { dispatchShareToAnalytics, dispatchViewCartToAnalytics } from '../../analytics';
import WishlistDrawer from './Wishlist';
import { handleCopy, returnRandomPerformingStore } from '../../utils';
import { useCustomToast } from './CustomToast';
import WalmartRealmAnimationMobile from '../WalmartRealmAnimationMobile';
import WalmartRealmAnimationTablet from '../WalmartRealmAnimationTablet';
import WalmartRealmAnimationDesktop from '../WalmartRealmAnimationDesktop';
import { shareWishListShareAnalytics } from '../../utils/wishlist';
import WalmartRealmAnimationSmallerDesktop from '../WalmartRealmAnimationSmallerDesktop';
import WalmartRealmAnimationBiggerDesktop from '../WalmartRealmAnimationBiggerDesktop';
// import axios from 'axios';

interface OverlayProps {
  children: React.ReactNode;
  setShowSite: (state: boolean) => void;
}

function Overlay({ children, setShowSite }: OverlayProps) {
  useViewportHeight();
  const navigate = useNavigate();
  const showToast = useCustomToast();
  const location = useLocation();
  const [uiLoaded, setUiLoaded] = useState(false);
  // const { width } = useWindowDimensions();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // const [activeView, setActiveView] = useState<string>('shops');
  // const [productIdTrail, setProductIdTrail] = useState<string[]>([]);

  // async function userRegionAllowed() {
  //   const res = await axios.get('https://api.ipify.org?format=json');
  //   if (res.status === 200) {
  //     if (['MX', 'CA'].includes(res.data.countryCode)) {
  //       console.log(res);
  //     } else {
  //       console.log('ERR');
  //     }
  //   }
  // }

  useEffect(() => {
    // userRegionAllowed();

    const fetchRegion = async () => {
      // const regionAllowed = await userRegionAllowed();
      // console.log('regionAllowed', regionAllowed);
      // if (regionAllowed) {
      setShowSplashScreen(false);
      setShowSite(true);
      setUiLoaded(true);
      // } else {
      //   setShowSplashScreen(false);
      //   setShowSite(false);
      //   setUiLoaded(false);
      // }
    };

    const timeoutId = setTimeout(() => {
      fetchRegion();
    }, 900);

    return () => clearTimeout(timeoutId); // Cleanup timeout
  }, []);

  const {
    bannerClosed,
    setBannerClosed,
    // productDrawerLoading,
    // productDrawerData,
    // setProductDrawerData,
    // setProductDrawerLoading,
    // productTrailStartedId,
    // setProductIdTrailStartedId,
    cartActive,
    setCartActive,
    wishlistActive,
    setWishlistActive,
    wishlistItems,
    setWishlistItems,
    cartItems,
    setCartItems,
  } = useGlobalStore();
  // const [arViewerActive, setArViewerActive] = useState<boolean>(false);

  useEffect(() => {
    const cart = localStorage.getItem('app_wm_cart');
    if (cart !== null) {
      setCartItems(JSON.parse(cart) as CartItemObject[]);
    }
  }, []);

  useEffect(() => {
    if (!cartActive) return;
    dispatchViewCartToAnalytics({
      ecommerce: { value: 0, currency: 'USD', items: [] },
      product_source: 'direct',
    });
  }, [cartActive]);

  const updateParentCart = (cart: CartItemObject[]) => {
    localStorage.setItem('app_wm_cart', JSON.stringify(cart));
    setCartItems(cart);
  };

  const updateParentIframe = (wishlist: WishlistItemObject[]) => {
    localStorage.setItem('app_wm_wishlist', JSON.stringify(wishlist));
    setWishlistItems(wishlist);
  };

  const navigateMap = {
    sojelly: '/viewer/sojelly/plaza',
    yallternative: '/viewer/yallternative/plaza',
    chromatic: '/viewer/chromatic/plaza',
    royal: '/viewer/royal/room',
    gift: '/viewer/gift/workshop',
    holiday: '/viewer/holiday/hosting',
    mountain: '/viewer/mountain/chalet',
    winter: '/viewer/winter/wonder',
    sanrio: '/viewer/sanrio/room',
    serene: '/viewer/serene/retreat',
    y2k: '/viewer/y2k/room',
    arcade: '/viewer/arcade/room',
    celestial: '/viewer/celestial/market',
    eclectic: '/viewer/eclectic/grandpa',
    anime: '/viewer/anime/way',
    exitStore: '/feed',
    enterShops: '/feed',
    enterDiscover: '/discover',
  };

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.data.type === 'nextShop' && navigateMap[event.data.payload as keyof typeof navigateMap]) {
        navigate(navigateMap[event.data.payload as keyof typeof navigateMap], {
          state: { playVideoFromButton: false },
        });
      } else if (event.data.type === 'lastShop' && navigateMap[event.data.payload as keyof typeof navigateMap]) {
        navigate(navigateMap[event.data.payload as keyof typeof navigateMap], {
          state: { playVideoFromButton: false },
        });
      } else if (event.data.type == 'updateCart') {
        updateParentCart(event.data.payload);
      } else if (event.data.type == 'updateWishlist') {
        updateParentIframe(event.data.payload);
      } else if (navigateMap[event.data as keyof typeof navigateMap]) {
        navigate(navigateMap[event.data as keyof typeof navigateMap]);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  // useEffect(() => {
  //   const activeSidebarOption = navbarOptions.find(
  //     (sidebarOption: NavbarOption) => sidebarOption.href === location.pathname,
  //   );

  //   activeSidebarOption ? setActiveView(activeSidebarOption.key) : setActiveView('');
  //   window?.dataLayer?.push({
  //     environment: 'feed',
  //     store: activeSidebarOption ? activeSidebarOption.key : '',
  //   });
  // }, [location.pathname]);

  // const navbarOptions: NavbarOption[] = [
  //   {
  //     href: '/feed',
  //     key: 'shops',
  //     text: 'Shops',
  //   },
  //   // {
  //   //   href: '/discover',
  //   //   key: 'discover',
  //   //   text: 'Discover',
  //   // },
  // ];

  // const openProductModal = async (
  //   productVariantId: string,
  //   scene: string,
  //   fromRecommendation = false,
  //   addToTrail = false,
  //   removeFromTrail = false,
  // ) => {
  //   let pId = productVariantId;

  //   console.log('productVariantId', productVariantId);
  //   console.log('scene', scene);
  //   console.log('fromRecommendation', fromRecommendation);
  //   console.log('addToTrail', addToTrail);
  //   console.log('removeFromTrail', removeFromTrail);

  //   if (removeFromTrail) {
  //     if (productIdTrail.length === 1) {
  //       pId = productTrailStartedId;
  //       setProductIdTrail([]);
  //       setProductIdTrailStartedId('');
  //     } else {
  //       pId = productIdTrail[1];
  //       setProductIdTrail((prevArray) => prevArray.slice(1));
  //     }
  //   } else if (addToTrail) {
  //     setProductIdTrail([productVariantId, ...productIdTrail]);
  //   } else {
  //     setProductIdTrailStartedId(productVariantId);
  //   }

  //   setProductDrawerLoading(true);
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/product/variant/${pId}`)
  //     .then((response) => {
  //       const product = response.data.data as ProductData;
  //       if (product.variants.some((variant) => variant.in_stock)) {
  //         setProductDrawerData({ productId: productVariantId, data: product, active: true, fromRecommendation });
  //         setProductDrawerLoading(false);
  //       } else {
  //         showToast({
  //           id: 'outOfStock',
  //           title: 'Sorry, this item is currently sold out. Check back later for a restock.',
  //           status: 'info',
  //
  //         });
  //         setProductDrawerLoading(false);
  //         setProductIdTrail([]);
  //         setProductIdTrailStartedId('');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       showToast({
  //         id: 'outOfStock',
  //         title: 'Sorry, this item is currently sold out. Check back later for a restock.',
  //         status: 'info',
  //
  //       });
  //       setProductDrawerLoading(false);
  //       setProductIdTrail([]);
  //       setProductIdTrailStartedId('');
  //     });
  // };

  // const activateARViewer = (productId: string) => {
  //   const redirectLink = process.env.REACT_APP_DOMAIN_NAME || '';
  //   const somethingWentWrongText = 'Something went wrong';
  //   const ModelFilename = ARPCUrls[productId as keyof typeof ARPCUrls]?.split('=')[1];

  //   const anchor = document.createElement('a');

  //   const detectedOs = getOpSys();

  //   if (detectedOs === 'IOS') {
  //     const fileDirectory = `${process.env.REACT_APP_DOMAIN_NAME}/` + ARIOSUrls[productId as keyof typeof ARIOSUrls];
  //     anchor.setAttribute('rel', 'ar');
  //     anchor.appendChild(document.createElement('img'));
  //     anchor.setAttribute('href', fileDirectory + '#allowsContentScaling=0');
  //     anchor.click();
  //   } else if (detectedOs === 'Android') {
  //     const fileDirectory =
  //       `${process.env.REACT_APP_DOMAIN_NAME}/` + ARAndoirdUrls[productId as keyof typeof ARAndoirdUrls];
  //     const fallbackUrl =
  //       'https://arvr.google.com/scene-viewer?file=' +
  //       encodeURIComponent(fileDirectory) +
  //       '&link=' +
  //       encodeURIComponent(redirectLink) +
  //       '&title=' +
  //       somethingWentWrongText +
  //       '';

  //     const intentConstruct =
  //       '?file=' +
  //       fileDirectory +
  //       '&title=' +
  //       ModelFilename +
  //       '&mode=ar_preferred#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=' +
  //       fallbackUrl +
  //       ';end;';

  //     const sceneViewerUrl = 'intent://arvr.google.com/scene-viewer/1.0' + intentConstruct;

  //     anchor.href = sceneViewerUrl;
  //     anchor.appendChild(document.createElement('img'));
  //     document.body.appendChild(anchor);
  //     anchor.click();
  //   } else {
  //     setArViewerActive(true);
  //   }
  // };

  const handleShareWishlist = async () => {
    const randomStore = returnRandomPerformingStore();
    const sharedPIds = wishlistItems.map((wishlistItem: WishlistItemObject) => wishlistItem.id);
    const sharedPIdsString = sharedPIds.join(',');

    const domain = `${process.env.REACT_APP_DOMAIN}/#/${randomStore}?sharedPIds=${sharedPIdsString}`;
    dispatchShareToAnalytics('wishlist', sharedPIdsString);
    shareWishListShareAnalytics();

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out my wishlist on Walmart Realm',
          text: 'Take a look at the great items I found on Walmart Realm:',
          url: domain,
        });
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      handleCopy(domain);
      showToast({
        id: 'linkCopiedWishlist',
        title: 'Link Copied',
        status: 'success',
        bottom: ['140px', '140px', '60px'],
      });
    }
  };

  return (
    <Box w="100%" height="100%" display="flex" flexDirection="column" position="relative">
      {/* {!location.pathname.includes('viewer') && ( */}
      {uiLoaded && !location.pathname.includes('viewer') && (
        <Image
          src={TopOverlay}
          w={['100%']}
          height={['160px', '170px', '200px', '230px', '280px']}
          objectFit="cover"
          objectPosition="center"
          position="fixed"
          zIndex="85"
          alt="top gradient overlay"
          loading="eager"
        />
      )}

      {/* {!location.pathname.includes('viewer') && ( */}
      {uiLoaded && !location.pathname.includes('viewer') && (
        <Navbar
          cartItems={cartItems}
          // activeView={activeView}
          // navbarOptions={navbarOptions}
          setCartActive={(state) => setCartActive(state)}
          openWishlist={() => setWishlistActive(true)}
        />
      )}

      {uiLoaded && (
        <Box display="flex" flex="1" transition="0.2s all" height={['100%']} zIndex="90">
          <Box flex="1" width={['100%']} display="flex" flexDirection="column">
            {children}
          </Box>
        </Box>
      )}

      {uiLoaded && (
        <Box
          zIndex="200"
          opacity={!bannerClosed ? 1 : 0}
          visibility={!bannerClosed ? 'visible' : 'hidden'}
          transition="all 0.2s"
          position="fixed"
          bottom="0px"
          bg="white"
          width="100%"
          height={['70px']}
          display="flex"
          flexDirection={['column', 'column', 'row']}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          gap={[1, 1, 6]}
        >
          <Text color="rgba(97, 97, 97, 1)" fontSize={['12px', '12px', '14px']} fontFamily="Bogle">
            © 2024 Walmart. All Rights Reserved.
          </Text>
          <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" gap={[4, 4, 6]}>
            <Button
              fontSize={['12px', '12px', '14px']}
              fontFamily="Bogle"
              fontWeight="100"
              textDecor="none"
              variant="link"
              color="rgba(97, 97, 97, 1)"
              onClick={() =>
                window.open(
                  'https://www.walmart.com/help/article/walmart-com-terms-of-use/3b75080af40340d6bbd596f116fae5a0',
                  '_blank',
                )
              }
            >
              Terms of Use
            </Button>

            <Button
              fontSize={['12px', '12px', '14px']}
              fontFamily="Bogle"
              fontWeight="100"
              textDecor="none"
              variant="link"
              color="rgba(97, 97, 97, 1)"
              onClick={() =>
                window.open('https://corporate.walmart.com/privacy-security/walmart-privacy-notice', '_blank')
              }
            >
              Privacy Notice
            </Button>
          </Box>

          <CloseIcon
            color="black"
            boxSize={[4]}
            right={['15px', '15px', '30px']}
            position="absolute"
            cursor="pointer"
            onClick={() => setBannerClosed(true)}
          />
        </Box>
      )}

      <Box
        opacity={showSplashScreen ? 1 : 0}
        visibility={showSplashScreen ? 'visible' : 'hidden'}
        transition={showSplashScreen ? 'unset' : 'visibility 0s linear 1s, opacity 1s'}
        className="loading"
        height="100vh"
        width="100vw"
        zIndex="500"
        position="fixed"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin="0"
        bg="red"
      >
        <Box className="svg-container" width="100%" height="100%" position="relative" overflow="hidden">
          <Box
            display={['flex', 'none', 'none', 'none', 'none']}
            alignItems="center"
            overflow="hidden"
            height="100%"
            width="100%"
          >
            <WalmartRealmAnimationMobile />
          </Box>

          <Box
            display={['none', 'flex', 'none', 'none', 'none']}
            alignItems="center"
            overflow="hidden"
            height="100%"
            width="100%"
          >
            <WalmartRealmAnimationTablet />
          </Box>

          <Box
            display={['none', 'none', 'flex', 'none', 'none']}
            alignItems="center"
            overflow="hidden"
            height="100%"
            width="100%"
          >
            <WalmartRealmAnimationSmallerDesktop />
          </Box>

          <Box
            display={['none', 'none', 'none', 'flex', 'none']}
            alignItems="center"
            overflow="hidden"
            height="100%"
            width="100%"
          >
            <WalmartRealmAnimationDesktop />
          </Box>

          <Box
            display={['none', 'none', 'none', 'none', 'flex']}
            alignItems="center"
            overflow="hidden"
            height="100%"
            width="100%"
          >
            <WalmartRealmAnimationBiggerDesktop />
          </Box>
        </Box>
      </Box>

      <CartDrawer
        data={cartItems}
        active={cartActive}
        close={() => setCartActive(false)}
        setCartItems={setCartItems}
        setWishlistItems={setWishlistItems}
        openWishlist={() => {
          setCartActive(false);
          setWishlistActive(true);
        }}
      />

      <WishlistDrawer
        data={wishlistItems}
        active={wishlistActive}
        close={() => setWishlistActive(false)}
        setCartItems={setCartItems}
        setWishlistItems={setWishlistItems}
        shareWishlist={handleShareWishlist}
      />

      {/* {productDrawerLoading && (
        <Spinner
          position="fixed"
          top="0px"
          left="0px"
          bottom="0px"
          right="0px"
          margin="auto"
          thickness="4px"
          speed="0.2s"
          color="white"
          size="xl"
          zIndex="9999"
        />
      )} */}

      {/* <ArViewer
        pId={productDrawerData.productId}
        productDrawerData={productDrawerData.data}
        active={arViewerActive}
        setCartItems={setCartItems}
        setWishlistItems={setWishlistItems}
        close={() => {
          setArViewerActive(false);
          setProductIdTrail([]);
          setProductIdTrailStartedId('');
          setProductDrawerData({ ...productDrawerData, active: false });
        }}
        openCart={() => {
          setArViewerActive(false);
          setProductDrawerData({ ...productDrawerData, active: false });
          setCartActive(true);
        }}
        openPDP={() => {
          setArViewerActive(false);
          setProductDrawerData({ ...productDrawerData, active: true });
        }}
      /> */}

      {/* <WMProductDrawer
        productId={productDrawerData.productId}
        productDrawerData={productDrawerData.data}
        productIdTrail={productIdTrail}
        active={productDrawerData.active}
        close={() => {
          setCartActive(false);
          setProductDrawerData({ ...productDrawerData, active: false });
        }}
        openCart={() => {
          setCartActive(true);
          setProductDrawerData({ ...productDrawerData, active: false });
        }}
        setCartItems={setCartItems}
        setWishlistItems={setWishlistItems}
        openProductModal={(variantId, fromRecommendation, addToTrail, removeFromTrail) =>
          openProductModal(variantId, '', fromRecommendation, addToTrail, removeFromTrail)
        }
        showOutOfStockToast={() => {
          showToast({
            id: 'outOfStock',
            title: 'Sorry, this item is currently sold out. Check back later for a restock.',
            status: 'info',
            
          });
        }}
        activateArViewer={() => {
          setProductDrawerData({ ...productDrawerData, active: false });
          activateARViewer(productDrawerData.productId);
        }}
        fromRecommendation={productDrawerData.fromRecommendation}
      /> */}
    </Box>
  );
}

export default Overlay;
