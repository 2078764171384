import { Box, Image, Text } from '@chakra-ui/react';
import WalmartLogo from '../assets/images/WM-Realm Logo-Latest.png';
import React from 'react';

function RegionNotAllowed() {
  return (
    <Box
      width="100%"
      height="100vh"
      bg="#003abd"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="20px"
      padding="20px"
    >
      <Image
        src={WalmartLogo}
        objectFit="contain"
        alt="walmart logo"
        loading="lazy"
        width={['180px', '180px', '200px', '200px', '220px']}
        mb={['20px']}
      />

      <Text
        fontSize={['18px', '18px', '20px', '20px', '22px']}
        lineHeight={['20px', '20px', '22px', '22px', '24px']}
        color="white"
        fontFamily="Bogle"
        fontWeight="400"
        textAlign="center"
      >
        Thank you for your interest in Walmart Realm. This experience is not available in your country/region at this
        time.
      </Text>
      <Text
        fontSize={['20px', '20px', '22px', '22px', '24px']}
        lineHeight={['22px', '22px', '24px', '24px', '26px']}
        color="white"
        fontFamily="Bogle"
        fontWeight="400"
        textAlign="center"
      >
        Gracias por tu interés en Walmart Realm. Esta experiencia no está disponible en tu país o región en este
        momento.
      </Text>
      <Text
        fontSize={['20px', '20px', '22px', '22px', '24px']}
        lineHeight={['22px', '22px', '24px', '24px', '26px']}
        color="white"
        fontFamily="Bogle"
        fontWeight="400"
        textAlign="center"
      >
        Merci de votre intérêt pour Walmart Realm. Cette expérience n’est pas disponible dans votre pays/région pour le
        moment.
      </Text>
    </Box>
  );
}

export default RegionNotAllowed;
